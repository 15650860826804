<!-- begin #header -->
<div class="header navbar-default" id="header">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <ng-container *ngIf="this.pageSidebarTwo">
      <button (click)="mobileSidebarToggle()" class="navbar-toggle pull-left" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <button (click)="mobileRightSidebarToggle()" class="navbar-toggle pull-right" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </ng-container>
    <a class="navbar-brand" [href]="urlsso"><img src="../../../../assets/img/logo.png" alt="Logo" class="logo-img">
      Litige
      <span *ngIf="view?.libelle">
        > {{view?.libelle}}
      </span>
    </a>
    <button (click)="mobileSidebarToggle()"
            *ngIf="!this.pageSettings.pageSidebarTwo && !this.pageSettings.pageWithoutSidebar" class="navbar-toggle"
            type="button">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <button (click)="mobileMegaMenuToggle()" *ngIf="this.pageSettings.pageMegaMenu" class="navbar-toggle p-0 m-r-0"
            type="button">
      <span class="fa-stack fa-lg text-inverse m-t-2">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button (click)="mobileTopMenuToggle()"
            *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar" class="navbar-toggle"
            type="button">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- end navbar-header -->

  <!-- begin mega-menu -->
  <div *ngIf="this.pageSettings.pageMegaMenu"
       [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }"
       class="collapse navbar-collapse pull-left">
    <ul class="nav navbar-nav">
      <li class="dropdown dropdown-lg" ngbDropdown>
        <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle><i class="fa fa-th-large fa-fw"></i> Mega <b
          class="caret"></b></a>
        <div class="dropdown-menu dropdown-menu-lg" ngbDropdownMenu>
          Mega menu content here
        </div>
      </li>
      <li>
        <a href="javascript:">
          <i class="fa fa-gem fa-fw"></i> Client
        </a>
      </li>
      <li class="dropdown" ngbDropdown>
        <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
          <i class="fa fa-database fa-fw"></i> New <b class="caret"></b>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu>
          <li><a href="javascript:">Action</a></li>
          <li><a href="javascript:">Another action</a></li>
          <li><a href="javascript:">Something else here</a></li>
          <li class="divider"></li>
          <li><a href="javascript:">Separated link</a></li>
          <li class="divider"></li>
          <li><a href="javascript:">One more separated link</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- end mega-menu -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">
    <li class="dropdown navbar-user" ngbDropdown *ngIf="view.context && view.context == 1">
      <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
        <div>
          <i class="fa fa-file-alt"></i>
          <span class="ml-2 d-none d-md-inline">Documents</span> <b class="caret"></b>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div>
          <a class="dropdown-item font-weight-bold cursor-pointer" target="_blank"
             ngbTooltip="SharePoint"
             placement="top"
             container="body"
             href="{{urlSharePoint}}"><i class="fas fa-file-pdf mr-1"></i> Guide utilisateur </a>
        </div>
      </div>
    </li>


    <li class="dropdown" ngbDropdown>
      <a class="dropdown-toggle f-s-14" (click)="getNotif()" href="javascript:" ngbDropdownToggle>
        <div *ngIf="loadingNbNotifs" class="spinner-border spinner-border-sm"></div>
        <div *ngIf="!loadingNbNotifs">
          <i class="fa fa-bell"></i>
          <span class="label label-badge">{{notifs.length}}</span>
        </div>
      </a>
      <ul class="dropdown-menu dropdown-menu-right p-0" style="width: 600px" ngbDropdownMenu>
        <li class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 452px;">
          <app-notification
            [data]="notifs"
            [loading]="loadingTableNotification"
            [showBtn]="showBtn"
            [notifLength]="notifLength"
            [loadingBtn]="loadingBtn"
            (selectCheckNotif)="checkNotif($event)"
            (selectdeleteNotif)="removeNotif($event)"
            (selectCheckAllNotif)="checkAllNotif()"
            (selectDeleteAllNotif)="removeAllNotif()">
          </app-notification>
        </li>
      </ul>
    </li>
    <li *ngIf="this.pageSettings.pageLanguageBar" class="dropdown navbar-language" ngbDropdown>
      <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
        <span class="flag-icon flag-icon-us" title="us"></span>
        <span class="name">EN</span> <b class="caret"></b>
      </a>
      <ul class="dropdown-menu p-b-0" ngbDropdownMenu>
        <li class="arrow"></li>
        <li><a href="javascript:"><span class="flag-icon flag-icon-us" title="us"></span> English</a></li>
        <li><a href="javascript:"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</a></li>
        <li><a href="javascript:"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</a></li>
        <li><a href="javascript:"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</a></li>
        <li class="divider m-b-0"></li>
        <li class="text-center"><a href="javascript:">more options</a></li>
      </ul>
    </li>
    <li class="dropdown navbar-user" ngbDropdown>
      <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
        <div class="image image-icon bg-black text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <span class="d-none d-md-inline"> {{currentUser.getFullname()}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a class="dropdown-item" href="javascript:">Mes paramètres</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item btn" (click)="logout()">Déconnexion</a>
      </div>
    </li>
    <li *ngIf="this.pageSidebarTwo" class="divider d-none d-md-block"></li>
    <li *ngIf="this.pageSidebarTwo" class="d-none d-md-block">
      <a (click)="toggleSidebarRight()" class="f-s-14" href="javascript:">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->
