<div class="error">
  <div class="error-code m-b-10">500</div>
  <div class="error-content">
    <div class="error-message">Une erreur est survenue </div>
    <div class="error-desc m-b-30">
     Veuillez contacter le service informatique <a href="mailto:info.agediss.com" class="text-white"><i class="fa fa-lg fa-ambulance"></i></a>
    </div>

    <div>
      <a routerLink="/" class="btn btn-agediss-inverse p-l-20 p-r-20">Retourner à l'accueil.</a>
    </div>
    <br/>


    <div>
      <img src="../../../../assets/img/logo.png" alt="Logo" class="logo-img">
      <h3 class="text-white">Litige</h3>
    </div>
  </div>
</div>
