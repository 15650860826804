<div class="error">
  <div class="error-code m-b-10">401</div>
  <div class="error-content">
    <div class="error-message">{{message}}</div>
    <div class="error-desc m-b-30">
    </div>
    <div>
      <a [href]="url" class="btn btn-agediss-inverse p-l-20 p-r-20">Retourner à la liste des applications.</a>
    </div>

    <br/>

    <div>
      <img src="../../../../assets/img/logo.png" alt="Logo" class="logo-img">
      <h3 class="text-white">Litige</h3>
    </div>
  </div>
</div>
